import React from 'react';
import Timetable from './Timetable/TimeTable';
import TopMenu from './TopMenu';

function App() {
  return (
    <>
      <TopMenu />
      <br />
      <Timetable />
    </>  
  );
}

export default App;
